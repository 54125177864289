<template>
  <svg
    height="3"
    preserveAspectRatio="none"
    viewBox="0 0 194 3"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="-0.00012207"
      width="100%"
      height="3"
    />
  </svg>
</template>
